import './src/polyfills';

import React from 'react';
import AppProvider from "./src/context/AppProvider";
import SmoothScroll from "smooth-scroll";
import vhCheck from 'vh-check';

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    {element}
  </AppProvider>
);

export const onClientEntry = async () => {
  vhCheck({
    force: false,
    bind: true
  });

  document.body.classList.remove('no-js');
  document.body.classList.add('js');

  if (typeof window !== "undefined") {
    new SmoothScroll('a[href*="#"]');
  }
}