import React, { useReducer } from "react";
import AppContext from './AppContext';

const initialState = {
    theme: null
};

function reducer(state, action) {
    if( process.env.NODE_ENV === 'development' ) {
        console.info(action);
    }

    switch (action.type) {
        case 'SET_THEME':
            return {
                ...state,
                theme: action.payload
            }
        default:
            return state;
    }
}
  
const AppProvider = ({ children }) => {
    const [ state, dispatch ] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            { children }
        </AppContext.Provider>
    )
}

export default AppProvider;